<template>
  <mobile-screen :header="true" screen-class="icon-app1 outlook-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="outlook-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpLinkName,
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          Map
        </div>
      </top-header-menu-wrapper>
    </template>
    <section
      class="map-iframe-wrapper"
      style="display: flex; width: 100%; position: relative; height: 100%"
      v-if="map"
    >
      <iframe
        ref="mapIframe"
        style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
        :src="map"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </section>
    <template v-slot:footer>
      <div class="outlook-footer-container">
        <button
          class="footer-button"
          :class="[action == 'remove' ? 'footer-button-remove' : '']"
          :style="[action == 'add' ? 'transform: rotate(45deg)' : '']"
          v-if="showDeclareIcon"
          @click="handleResources"
        >
          <!-- <span style="font-size: 30px;">X</span> -->
          <span class="svg-icon"><icon icon="#cx-map1-delete-30x30"/></span>
        </button>
      </div>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import { mapState, mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "OutlookMap",
  data() {
    return {
      showDeclareIcon: false,
      resourceId: null,
      slots: null,
      type: null,
      name: null,
      action: "remove",
      helpSlug: "plan-resources-5dmap",
      email: null,
    };
  },
  created() {
    if (!this.map) {
      this.$router.push({ name: this.backLinkName });
    }
    window.addEventListener("message", this.readMessage, false);
  },
  computed: {
    ...mapState("outlook", ["map", "enhancedLocation", "resources"]),
  },
  methods: {
    ...mapActions("outlook", ["setMap"]),
    readMessage(event) {
      const envVariables = process.env;
      const { data } = event;
      const { VUE_APP_5DMAPS_URL } = envVariables;

      if (event.data.call === "outlookData") {
        this.$router.push({ name: this.backLinkName });
      } else {
        if (event.origin === VUE_APP_5DMAPS_URL) {
          if (
            data.resourceId &&
            data.eventName === "outlookSelectResource" &&
            data.email
          ) {
            this.action = data.action;
            this.email = data.email;
            this.resourceId = data.resourceId;
            this.showDeclareIcon = true;
          } else {
            this.showDeclareIcon = false;
            this.email = false;
          }
        }
      }
    },
    handleResources() {
      this.showDeclareIcon = false;

      if (this.email) {
        const val = this.enhancedLocation.find(
          (el) => el.locationIdentifier.id === this.email
        );
        if (this.action == "remove") {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: JSON.parse(JSON.stringify(val.locationIdentifier)),
            },
            "*"
          );
          this.$refs.mapIframe.contentWindow.postMessage(
            {
              call: "outlookResourceRemove",
              value: this.resourceId,
            },
            "*"
          );
        } else {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: { id: this.email, type: "room" },
              add: true,
            },
            "*"
          );
          this.$refs.mapIframe.contentWindow.postMessage(
            {
              call: "outlookResourceAdd",
              value: this.resourceId,
            },
            "*"
          );
        }
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("message", this.readMessage);
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  mixins: [PlanMixin, helpOnlineMixin, mapMixin],
  props: {
    backLinkName: {
      type: String,
    },
  },
};
</script>
<style>
.outlook-footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.footer-button {
  align-items: center;
  background: linear-gradient(180deg, #247eb7 0%, #24b7af 100%);
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  font-size: 0.9375rem;
  height: 3.75rem;
  letter-spacing: -0.05187rem;
  line-height: 1.25rem;
  justify-content: center;
  text-transform: uppercase;
  text-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  width: 3.75rem;
  position: relative;
  top: -20px;
  cursor: pointer;
}
.footer-button-remove {
  background: linear-gradient(180deg, #b72424 0%, #ef887a 100%);
}
.footer-button svg {
  display: inline-block;
  max-width: 90%;
  position: relative;
  top: 2px;
  left: 0.5px;
}
.outlook-footer-container .svg-icon {
  --color-2: #eae9e9;
}

.footer-button-inactive {
  background: lightgray;
  --color-2: gray;
  cursor: auto;
}
</style>
